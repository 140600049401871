<template>
    <div>
        <div class="content-blog-grid mt-2" v-if="relateArticlesByCategory.length > 0">
            <div class="content-bar mb-2">
                <div class="bar-title">
                    <span @click="knowledgeCategory = 'all'">รู้เรื่องยางรถยนต์</span>
                    <div class="desktop-knowledge-category d-none d-md-block">
                        <button :class="{ 'active': knowledgeCategory === item.filter }" 
                                @click="knowledgeCategory = item.filter"
                                v-for="(item, index) in relateArticlesByCategory.filter(x => x.filter !== 'all')"
                                :key="index">
                            {{ item.title }}
                        </button>
                    </div>
                </div>
                <router-link :to="routerPath.ARTICLE" class="view-more" title="รู้เรื่องยางรถยนต์">ดูทั้งหมด <b-icon icon="chevron-right"></b-icon></router-link>
            </div>
           <div class="mobile-knowledge-category d-md-none" v-if="relateArticlesByCategory.length > 0">
                <dropdown
                    v-model="knowledgeCategory"
                    :options="getOptionArticleCategory()"
                    :noTyping="true"
                    class="input-option"
                    :classes="{ input: ['square'] }">
                </dropdown>
            </div>
            <div v-for="(item, index) in relateArticlesByCategory"
                :key="index">
                <blog-content
                    :contents="item.contents"
                    v-if="knowledgeCategory === item.filter">
                </blog-content>
            </div>
        </div>

        <div class="content-blog-grid mt-2" v-if="relatePromotions.length > 0">
            <div class="content-bar mb-2">
                <div class="bar-title">โปรโมชั่นยางรถยนต์ ล่าสุด</div>
                <router-link :to="routerPath.PROMOTION" class="view-more" title="โปรโมชั่นยางรถยนต์ล่าสุด">ดูทั้งหมด <b-icon icon="chevron-right"></b-icon></router-link>
            </div>
            <blog-content
                :contents="relatePromotions">
            </blog-content>
        </div>

        <div class="content-blog-grid mt-2" v-if="relateArticlesLatest.length > 0">
            <div class="content-bar mb-2">
                <div class="bar-title">รวมข่าวสารวงการรถยนต์และยานยนต์</div>
                <router-link :to="routerPath.ARTICLE" class="view-more" title="รวมข่าวสารวงการรถยนต์และยานยนต์">ดูทั้งหมด <b-icon icon="chevron-right"></b-icon></router-link>
            </div>
            <blog-content
                :contents="relateArticlesLatest">
            </blog-content>
        </div>
    </div>
</template>

<script>
import RouterPath from '@/router/path';
import BlogContent from '@/components/blog/BlogContent';

export default {
    components: {
		BlogContent
	},
    data() {
        return {
            routerPath: RouterPath,
            knowledgeCategory: 'all'
        }
    },
    props: {
		relatePromotions: {
            type: Array,
            default: () => []
        },
		relateArticlesLatest: {
            type: Array,
            default: () => []
        },
		relateArticlesByCategory: {
            type: Array,
            default: () => []
        }
    },
    methods: {
		getOptionArticleCategory() {
			const option = [];

			this.relateArticlesByCategory.forEach((item) => {
				option.push({
					id: item.filter,
					name: item.title
				});
			});

			return option;
		}
    }
}
</script>